<template>
      <p>{{task.time}} on {{task.date}} @ {{task.location}}</p>
      <p>{{task.description}}</p>
</template>

<script>
export default {
  props: ['task']
}
</script>

<style scoped>
</style>
