<template>
      <p>Edit Task</p>
</template>

<script>
export default {
  props: ['task']
}
</script>

<style scoped>
</style>
