<template>
      <p>Delete</p>
      <button type="button" @click="deleteTask()" class="btn btn-danger">Delete Task</button>
</template>

<script>
export default {
  props: ['task'],
  inject: ['GStore'],
  methods: {
    deleteTask () {
      console.log('Task Deleted')
      this.GStore.flashMessage = 'Task' + this.task.title + ' was deleted'
      setTimeout(() => {
        this.GStore.flashMessage = ''
      }, 3000)
      this.$router.push({
        name: 'TaskDetails'
      })
    }
  }
}
</script>

<style scoped>
</style>
