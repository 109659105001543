<template>
    <div class="card" style="width: 24rem;">
      <div class="card-header">
        Tasks
      </div>
    <task-card v-for="task in tasks" :key="task.id" :task="task"/>

<div class="pagination">
  <router-link
    :id="prev"
    :to="{ name: 'TaskList', query: {page: page - 1}}"
    v-if="page != 1"> &#60; Prev page
  </router-link>
  <router-link
    :id="next"
    :to="{ name: 'TaskList', query: {page: page + 1}}"
    v-if="hasNextPage"> Next page &#62;
  </router-link>
  </div>
    </div>
</template>

<script>
// @ is an alias to /src
import TaskCard from '../components/TaskCard.vue'
// import axios from 'axios'
import TasksService from '@/services/TasksService.js'
import { watchEffect } from 'vue'

export default {
  name: 'TaskList',
  props: ['page'],
  components: {
    TaskCard
  },
  data () {
    return {
      tasks: null,
      totalTasks: 0
    }
  },
  computed: {
    hasNextPage () {
      var totalPages = Math.ceil(this.totalTasks / 2)
      return this.page < totalPages
    }

  },
  created () {
    watchEffect(() => {
    // axios.get('http://my-json-server.typicode.com/shaunborg0/MyJSONServer/tasks')
      console.log('Page is ' + this.page)
      TasksService.getTasks(2, this.page)
        .then(response => {
          this.tasks = response.data
          this.totalTasks = response.headers['x-total-count']
        })
        .catch(error => {
          console.log('ERRORS' + error)
        })
    })
  }
}

</script>
<style scoped>
.card {
  margin: auto;
}
.pagination{
  display: flex;
  width: 382;
}

.pagination a{
  flex: 1;
  text-decoration: none;
  color: black;
}

#prev{
  text-align: left;
}

#next{
  text-align: right;
}
</style>
