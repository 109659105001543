import { createRouter, createWebHistory } from 'vue-router'
import TaskList from '../views/TaskList.vue'
import About from '../views/About.vue'
import TaskLayout from '@/views/task/Layout.vue'
import TaskDetails from '@/views/task/Details.vue'
import TaskEdit from '@/views/task/Edit.vue'
import TaskDelete from '@/views/task/Delete.vue'
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'TaskList',
    component: TaskList,
    props: route => ({ page: parseInt(route.query.page) || 1 })
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/tasks/:id',
    name: 'TaskLayout',
    props: true,
    component: TaskLayout,
    children: [
      {
        path: '',
        name: 'TaskDetails',
        component: TaskDetails
      },
      {
        path: 'edit',
        name: 'TaskEdit',
        component: TaskEdit
      },
      {
        path: 'delete',
        name: 'TaskDelete',
        component: TaskDelete
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
