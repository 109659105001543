<template>
  <div class="about">
    <h1>This is an about page</h1>
    <button type="button" class="btn btn-primary">Primary</button>

  </div>
</template>

<style scoped>
</style>
